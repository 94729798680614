import React from 'react';
import IndustryDataTypeTemplate from '@components/IndustryDataType/IndustryDataTypeTemplate';
import { dataTypes, industries } from '@constants/industriesAndDatatypes';
import BackgroundImage from './assets/Optical_Imaging_Cover.jpg';
import metaImage from './assets/meta.jpg';

const OpticalImagingPage: React.FC = () => {
  return (
    <IndustryDataTypeTemplate
      pageName="data-type-optical-imaging"
      helmet={{
        title: 'TileDB - Securely manage & collaborate on biomedical imaging data',
        description:
          'Tailor performance across diverse clinical workflows using TileDB for efficient, low-cost access to 2D and 3D optical imaging data in biomedical microscopy.',
        shareImage: {
          url: metaImage,
          width: 1200,
          height: 627,
        },
      }}
      heroImage={BackgroundImage}
      icon={dataTypes.opticalImaging.icon}
      header="Biomedical imaging"
      title="Securely manage and collaborate on biomedical imaging data"
      overview={
        <>
          <p>
            Biomedical imaging data creates unique data governance and security challenges for clinical analysis. Hospital systems must
            ensure strict access controls, while facilitating internal collaboration across distributed teams. The complexity increases when
            massive amounts of images are needed for training machine learning models.
          </p>
          <p>
            Manage the scale of biomedical image recognition and other ML projects using the TileDB Cloud universal database. The platform’s
            data governance and access controls allow you to securely share and version all data assets in your clinical workflows, from
            flat files and Jupyter notebooks to ML models and the datasets used to train them.
          </p>
          <p>
            At the core of TileDB is its universal data model built on multi-dimensional arrays. 2D images for pathology workflows and 3D
            images for volumetric reconstruction are all naturally represented as cloud-native TileDB arrays, allowing you to tailor
            microscopy data across a range of healthcare and life sciences applications. TileDB arrays provide a canonical, open-spec data
            format that can represent all clinical data, while enabling rapid slicing and iterative data science, AI and ML development.
          </p>
        </>
      }
      relatedItems={[industries.healthcare]}
    />
  );
};

export default OpticalImagingPage;
